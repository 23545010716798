/* The following line can be included in a src/App.scss */
@import '~bootstrap/scss/bootstrap';

.order-grid {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1rem;

  &--box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
  }
}
